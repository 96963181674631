import moment from "moment-timezone"
import Connector from "@/util/Connector"
import useGeneralStore from "@/stores/general"

export default class ActorTimezoneHandler {

    static dateInUserTz(date: string) {
        const timezone = this.getUserTz();
        return moment(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss Z').toString();
    }

    static guessUserTz(): string {
        return  moment.tz.guess()
    }

    static setUserTz(selectedTz: string)  {
        Connector.setActorTimezone({timezone: selectedTz});
        const generalStore = useGeneralStore()
        generalStore.setTimezone(selectedTz)
        localStorage.setItem("actorTimezone", selectedTz);
    }
    
    static getUserTz(): string {    
        const generalStore = useGeneralStore()
        return  generalStore.getTimezone()
    }

}