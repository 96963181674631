import {ErrorType} from "./ErrorType";

export default interface GraphQlError {
    message: string;
    errorType: ErrorType;
}

export interface GraphQlResult {
    request: any;
    response: GraphQlResponse;
}

export interface GraphQlResponse {
    errors: GraphQlError[];
    headers: any;
    meta: any;
    status: number;
}

export class ErrorUtil {
    static getMessage = (response: GraphQlResponse): string => {
        if (response && response.errors) {
            const message = response.errors[0].message;
            if (message.startsWith("Exception while fetching data (")) {
                const regex = /Exception while fetching data \(.*?\) : (.*)/gm;
                const r = regex.exec(message);
                if (r != null && r.length > 1) {
                    return r[1];
                }
            }
            return message;
        }
        return "";
    };
}