import { defineStore } from 'pinia'
import { ErrorType } from '@/models/ErrorType';
import SnackBarMessage from '@/models/SnackBarMessage';
import {ErrorUtil, GraphQlResponse, GraphQlResult} from "@/models/GraphQlError";
import {SnackBarMessageType} from "@/models/SnackBarMessageType";

export default defineStore('general', {
    state: () => {
        return {
            errorState: undefined as ErrorType | undefined,
            pageLoading: false as boolean,
            snackStack: [] as SnackBarMessage[],
            username: "dummyuser" as string,
            version: "v0.0.0.0" as string,
            siteName: "dev" as string,
            domain: "pt" as string,
            timezone: "GMT" as string,
            userGuide: "" as string,
            permissions: [] as string[]
        }
    },

    actions: {
        /**
         *
         * @param snack Snackbar message to be displayed.
         * @param queued Previous snacks will be kept in history/queue.
         */
        addSnack(snack: SnackBarMessage, queued: boolean){
            if (queued){
                this.snackStack.push(snack);
            } else {
                this.snackStack = [snack];
            }
        },

        addErrorSnack(wrapper: GraphQlResult | GraphQlResponse, message?: string) {
            if (message != undefined) {
                message = message.trim();
                if (![".", "!"].some(c => message && message.length > 0 && message.endsWith(c))) {
                    message += "."
                }
            } else {
                message = "";
            }

            const response = "response" in wrapper ? wrapper.response : wrapper;
            message = `${message} ${ErrorUtil.getMessage(response)}`;

            this.addSnack({
                type: SnackBarMessageType.ERROR,
                message: message,
                meta: response.meta
            }, true);
        },


        addInfoSnack(message: string) {
            this.addSnack({
                type: SnackBarMessageType.MESSAGE,
                message: message
            }, true);
        },

        addSuccessSnack(message: string) {
            this.addSnack({
                type: SnackBarMessageType.SUCCESS,
                message: message
            }, true);
        },

        addSimpleExceptionSnack(message: string) {
            this.addSnack({
                type: SnackBarMessageType.ERROR,
                message: message
            }, true);
        },

        getTimezone(){
            return this.timezone;
        },

        setTimezone(timezone: string){
            this.timezone = timezone;
        }
    }
})