import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {ApolloClient} from 'apollo-client';
import {defaultDataIdFromObject, InMemoryCache} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client';
import {onError} from 'apollo-link-error';
import {from} from 'apollo-link';
import useGeneralStore from "@/stores/general";
import {SnackBarMessageType} from "@/models/SnackBarMessageType";



const baseURL = process.env.VUE_APP_API_PATH + '/graphql';
const publicBaseURL = process.env.VUE_APP_API_PATH + '/graphql_public';

// HTTP connection to the API
const httpLink = createUploadLink({
    uri: (operation) => `${baseURL}?operationName=${operation.operationName}`,
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
});

const publicHttpLink = createUploadLink({
    uri: (operation) => `${publicBaseURL}?operationName=${operation.operationName}`,
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
});

let preventReload = false;
let disableSessionExpirePrompt = false;

const errorLink = onError(({graphQLErrors, networkError, response}) => {
    const store = useGeneralStore();

    if (graphQLErrors && graphQLErrors.length) {
        store.addErrorSnack(response);
    } else if (networkError) {
        if (networkError?.response?.redirected) {
            if (!window.sessionStorage.reloaded && !preventReload) {
                window.sessionStorage.reloaded = 1;
                disableSessionExpirePrompt = true;
                location.reload();
            } else if (!disableSessionExpirePrompt) {
                store.addSnack({message: "Your session has expired!", type: SnackBarMessageType.ERROR}, false);

                preventReload = true;
                window.sessionStorage.removeItem('reloaded');
            }
        } else {
            store.addSnack({message: networkError.message, type: SnackBarMessageType.ERROR}, false);
        }
    }
});

// Cache implementation
const cache = new InMemoryCache({
    possibleTypes: {
        Actor: ["AdminAccount", "TechnicalAccount"]
    },
    dataIdFromObject: (object) => {
        switch (object.__typename) {
            case 'Audit':
                return `${object.__typename}:${object.key}`;
            default:
                return defaultDataIdFromObject(object); // Fall back to default
        }
    },
});

const apolloClient = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache,
});

const publicApolloClient = new ApolloClient({
    link: from([errorLink, publicHttpLink]),
    cache,
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    clients: {
        apolloClient,
        publicApolloClient
    }
});

export default apolloProvider;
export {apolloClient, publicApolloClient};